
var jsCloud = function (dom, word_array, options) {
    // Reference to the container element
    var $this = dom;
    // Namespace word ids to avoid collisions between multiple clouds
    var cloud_namespace = $this.style.id || Math.floor((Math.random() * 1000000)).toString(36);
    // Default options value
    var default_options = {
        width: parseFloat(dom.style.width, 2),
        height: parseFloat(dom.style.height, 2),
        center: {
            x: ((options && options.width) ? options.width : parseFloat(dom.style.width, 2)) / 2.0,
            y: ((options && options.height) ? options.height : parseFloat(dom.style.height, 2)) / 2.0
        },
        delayedMode: word_array.length > 50,
        shape: false, // It defaults to elliptic shape
        encodeURI: true,
        removeOverflowing: true
    };

    options = Object.assign(default_options, options || {});

    // Add the "jqcloud" class to the container for easy CSS styling, set container width/height
    $this.classList.add("jqcloud");
    $this.style.weight = options.weight;
    $this.style.height = options.height;

    // Container's CSS position cannot be 'static'

    if ($this.style.position === "static") {
        $this.style.position = "position";
    }

    var drawWordCloud = function () {
        // Helper function to test if an element overlaps others
        var hitTest = function (elem, other_elems) {
            // Pairwise overlap detection
            var overlapping = function (a, b) {
                if (Math.abs(2.0 * a.offsetLeft + a.offsetWidth - 2.0 * b.offsetLeft - b.offsetWidth) < a.offsetWidth + b.offsetWidth) {
                    if (Math.abs(2.0 * a.offsetTop + a.offsetHeight - 2.0 * b.offsetTop - b.offsetHeight) < a.offsetHeight + b.offsetHeight) {
                        return true;
                    }
                }
                return false;
            };
            var i = 0;
            // Check elements for overlap one by one, stop and return false as soon as an overlap is found
            for (i = 0; i < other_elems.length; i++) {
                if (overlapping(elem, other_elems[i])) {
                    return true;
                }
            }
            return false;
        };

        // Make sure every weight is a number before sorting
        for (var i = 0; i < word_array.length; i++) {
            word_array[i].weight = parseFloat(word_array[i].weight, 10);
        }

        // Sort word_array from the word with the highest weight to the one with the lowest
        word_array.sort(function (a, b) { if (a.weight < b.weight) { return 1; } else if (a.weight > b.weight) { return -1; } else { return 0; } });

        let step = (options.shape === "rectangular") ? 18.0 : 2.0,
            already_placed_words = [],
            aspect_ratio = options.width / options.height;

        // Function to draw a word, by moving it in spiral until it finds a suitable empty place. This will be iterated on each word.
        var drawOneWord = function (index, word) {
            // Define the ID attribute of the span that will wrap the word, and the associated jQuery selector string
            var word_id = cloud_namespace + "_word_" + index,
                word_selector = "#" + word_id,
                angle = 6.28 * Math.random(),
                radius = 0.0,

                // Only used if option.shape == 'rectangular'
                steps_in_direction = 0.0,
                quarter_turns = 0.0,

                weight = 5,
                custom_class = "",
                inner_html = "",
                word_span;

            // Extend word html options with defaults
            word.html = Object.assign(word.html ? word.html : {}, { id: word_id });
            // If custom class was specified, put them into a variable and remove it from html attrs, to avoid overwriting classes set by jQCloud
            if (word.html && word.html["class"]) {
                custom_class = word.html["class"];
                delete word.html["class"];
            }

            // Check if min(weight) > max(weight) otherwise use default
            if (word_array[0].weight > word_array[word_array.length - 1].weight) {
                // Linearly map the original weight to a discrete scale from 1 to 10
                weight = Math.round((word.weight - word_array[word_array.length - 1].weight) /
                    (word_array[0].weight - word_array[word_array.length - 1].weight) * 9.0) + 1;
            }

            let dd = document.createElement("span");
            dd.className = 'w' + weight + " " + custom_class
            word_span = new Array(dd);

            // Append link if word.url attribute was set
            if (word.link) {
                // If link is a string, then use it as the link href
                if (typeof word.link === "string") {
                    word.link = { href: word.link };
                }

                // Extend link html options with defaults
                if (options.encodeURI) {
                    word.link = Object.assign(word.link ? word.link : {}, { href: encodeURI(word.link.href).replace(/'/g, "%27") });
                }
                let a = document.createElement("a");
                a.href = word.link;
                a.innerHTML = word.text;
                inner_html = a;
            } else {
                inner_html = word.text;
            }

            word_span[0].append(inner_html);

            $this.appendChild(word_span[0]);

            var width = word_span[0].style.width,
                height = word_span[0].style.height,
                left = options.center.x - width / 2.0,
                top = options.center.y - height / 2.0;

            // Save a reference to the style property, for better performance
            var word_style = word_span[0].style;
            word_style.position = "absolute";
            word_style.left = left + "px";
            word_style.top = top + "px";

            while (hitTest(word_span[0], already_placed_words)) {
                // option shape is 'rectangular' so move the word in a rectangular spiral
                if (options.shape === "rectangular") {
                    steps_in_direction++;
                    if (steps_in_direction * step > (1 + Math.floor(quarter_turns / 2.0)) * step * ((quarter_turns % 4 % 2) === 0 ? 1 : aspect_ratio)) {
                        steps_in_direction = 0.0;
                        quarter_turns++;
                    }
                    switch (quarter_turns % 4) {
                        case 1:
                            left += step * aspect_ratio + Math.random() * 2.0;
                            break;
                        case 2:
                            top -= step + Math.random() * 2.0;
                            break;
                        case 3:
                            left -= step * aspect_ratio + Math.random() * 2.0;
                            break;
                        case 0:
                            top += step + Math.random() * 2.0;
                            break;
                    }
                } else { // Default settings: elliptic spiral shape
                    radius += step;
                    angle += (index % 2 === 0 ? 1 : -1) * step;
                    left = options.center.x - (width / 2.0) + (radius * Math.cos(angle)) * aspect_ratio;

                    top = options.center.y + radius * Math.sin(angle) - (height / 2.0);
                }
                word_style.left = left + "px";
                word_style.top = top + "px";
            }

            already_placed_words.push(word_span[0]);
        };

        for (let i = 0; i < word_array.length; i++) {
            drawOneWord(i, word_array[i]);
        }
    };

    // Delay execution so that the browser can render the page before the computatively intensive word cloud drawing
    setTimeout(function () { drawWordCloud(); }, 10);
    return $this;
};
export default jsCloud;